import Page from '#/components/shared/ui/Page';
import ImprintContainer from '#/components/pages/Imprint/ImprintContainer';

const Imprint = () => (
  <Page title="Imprint">
    <ImprintContainer />
  </Page>
);

export default Imprint;
