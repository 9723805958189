import { Box, Stack, Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';

const ImprintContainer = () => {
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: 10,
        px: {
          xs: 2,
          md: 15,
        },
        gap: 8,
      }}
    >
      <Typography variant="h1" textAlign="center" fontWeight="bold">
        {String(translate('imprint.title'))}
      </Typography>
      <Stack spacing={3}>
        <Typography variant="body1">
          {String(translate('imprint.intro'))}
        </Typography>
        <Typography variant="h4">
          {String(translate('imprint.headquarter'))}
        </Typography>
        <Typography variant="body1">
          gut geregelt AG, Gewerbestrasse 12, 4450 Sissach, Switzerland
        </Typography>
        <Typography variant="body1">Email: info@gutgeregelt.ch</Typography>
        <Typography variant="body1">
          UID: CHE445.380.178 Trade Register Number: CH280.3.029.4287 Commercial
          Handelsregister: Basel-Landschaft
        </Typography>
        <Typography variant="body1">
          Verwaltungsratspräsident: Alain Veuve
        </Typography>
      </Stack>
    </Box>
  );
};

export default ImprintContainer;
